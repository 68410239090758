html {
  --tabbar-height: 100px;
  --body-width: 7.5rem;
  font-size: 13.33333333vw !important;
}
@media screen and (max-width: 320PX) {
  html {
    font-size: 42.66666667PX;
  }
}
@media screen and (min-width: 540PX) {
  html {
    --body-width: 540PX;
    font-size: 72PX;
  }
}
body {
  max-width: 100vw;
  min-width: 320PX;
  min-height: 100vh;
  margin: 0 auto !important;
  background: #f4f4f4;
  color: #000;
  font-size: 0.25rem;
  line-height: 0.36rem;
}
.van-tabbar {
  max-width: 100vw;
  margin: 0 auto;
}
.van-tabbar--fixed {
  left: unset;
}
.van-tabbar {
  border-top: 1px solid #ebedf0;
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .rui-tabbar {
    height: 80px;
  }
}
#nprogress .bar {
  background: #07c160;
  height: 1px;
}
#nprogress .spinner-icon {
  border-top-color: #07c160;
  border-left-color: #07c160;
}
#nprogress .peg {
  box-shadow: 0 0 0.2rem #07c160, 0 0 0.1rem #07c160;
}
.rui-list .van-empty {
  min-height: 70vh;
  justify-content: flex-start;
}
.rui-list .van-tab {
  font-size: 30px;
}
.rui-list .van-swipe-cell__right button,
.rui-list .van-swipe-cell__left button {
  height: 100%;
  border: 0;
}
.rui-list .van-swipe-cell .van-cell:last-child::after,
.rui-list .van-swipe-cell .van-cell--borderless::after {
  display: unset;
}
.van-dropdown-menu__bar {
  box-shadow: unset !important;
  border-bottom: 1px solid #ebedf0;
}
.van-grid .van-grid-item__icon {
  position: relative;
}
.van-checkbox,
.van-radio {
  margin-bottom: 5px;
}
.van-dialog .van-dialog__message {
  margin-top: 10px;
}
.rui-swiper .van-swipe__indicator--active {
  width: 15px;
  border-radius: 5px;
}
.rui-filter {
  display: flex;
  align-items: center;
}
.rui-filter .van-dropdown-menu {
  flex: 1;
}
.rui-filter .rui-filter-button {
  height: 45px;
  border-radius: unset;
  border: unset;
  border-bottom: 1px solid #ebedf0;
  background-color: #fff;
  color: #323233;
}
.rui-filter .rui-filter-button.van-button--disabled {
  opacity: 1;
}
.rui-hv-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
}
.unsticky .van-sticky {
  position: relative;
}
.rui-fab {
  position: fixed;
  bottom: 150px;
  right: 0px;
  opacity: 0.7;
}
.rui-qrcode {
  border: 1px solid #dcdee0;
}
.rui-edit-footer {
  padding: 20px;
}
.rui-edit-footer .van-button--block {
  margin-bottom: 12px;
}
.rui-edit-footer .van-button--block:last-child {
  margin-bottom: 0;
}
.rui-edit-footer.buttons-in-row {
  display: flex;
  flex-direction: row;
}
.rui-edit-footer.buttons-in-row .van-button--block {
  margin: 0 12px 0 0;
}
.rui-edit-footer.buttons-in-row .van-button--block:last-child {
  margin: 0;
}
.rui-button-field.van-field {
  padding: 0.12rem 0.32rem;
}
.rui-button-field .van-field__label {
  padding-top: 0.08rem;
}
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
