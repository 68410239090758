#app {
  position: relative;
  height: 100vh;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma, Simsun, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: white;
  -webkit-overflow-scrolling: touch;
}
.rui-tabbar {
  background-color: white;
}
[v-cloak] {
  display: none;
}
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
