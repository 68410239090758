// 设计稿宽度
@width_design: 750;
@width_max: 100vw;
// 为什么设置 100? 设置成便于计算, 计算rem时只需要将 设计稿的宽度 / 100 即可
// 如果给的是 375 的标注图, 那就设置成 50 即可
@html_fontsize: 100;

html {
	--tabbar-height: 100px;
	--body-width: 7.5rem;
	font-size: @html_fontsize / @width_design * 100vw!important;
	// 同时，通过Media Queries 限制根元素最大最小值
	@media screen and (max-width: 320PX) {
		font-size: 320PX / @width_design * @html_fontsize;
	}
	@media screen and (min-width: 540PX) {
		--body-width: 540PX;
		font-size: 540PX / @width_design * @html_fontsize;
	}
}
// body 也增加最大最小宽度限制，避免默认100%宽度的 block 元素跟随 body 而过大过小
body {
	max-width: @width_max;
	min-width: 320PX;
	min-height: 100vh;
	margin: 0 auto !important;
	background: #f4f4f4;
	color: #000;
	font-size: 0.25rem;
	line-height: 0.36rem;
}
.van-tabbar {
	max-width: @width_max;
	margin: 0 auto;
}
.van-tabbar--fixed {
	left: unset;
}
