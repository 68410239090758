
































































































































.floatball {
  position: fixed;
  right: 0;
  // bottom: 120px;
  z-index: 999 !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  width: 40px;
  // height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 5px;
  user-select: none;
  .float-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
  }
  .van-image {
    width: 15px;
    height: 15px;
  }
}

@hack: true;
          @import "E:\project\lfx_simple_frontend\src\assets\css\mixin.less";
          @import "E:\project\lfx_simple_frontend\src\assets\css\variable.less";
          ;