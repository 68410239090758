.floatball {
  position: fixed;
  right: 0;
  z-index: 999 !important;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px 0 0 5px;
  user-select: none;
}
.floatball .float-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
}
.floatball .van-image {
  width: 15px;
  height: 15px;
}
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
