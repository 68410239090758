

























































@import '~@/assets/css/custom.less';
.wrap {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  .van-image {
    width: 100%;
    height: 100%;
  }
  .count-down {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    padding: 3px 7px;
    text-align: center;
    line-height: 150%;
    font-size: @fz-14;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 16px;
  }
}

@hack: true;
          @import "E:\project\lfx_simple_frontend\src\assets\css\mixin.less";
          @import "E:\project\lfx_simple_frontend\src\assets\css\variable.less";
          ;