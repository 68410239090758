h1,
h2,
h3,
h4,
h5,
h6,
p,
 /deep/ p,
ul,
li {
  margin: 0;
}
.bold {
  font-weight: bold;
}
.block {
  width: 100%;
  height: 60px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.three-line {
  word-wrap: break-word;
  /* 内容存在英语或数字时强制换行 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  /* 显示省略符号来代表被隐藏的文本 */
  display: -webkit-box;
  /* 将对象作为弹性伸缩盒子模型显示 */
  -webkit-box-orient: vertical;
  /* 设置盒子内排列顺序为纵向 */
  -webkit-line-clamp: 3;
  /* 限制块元素显示的文本的行数 */
}
.second-line {
  word-wrap: break-word;
  /* 内容存在英语或数字时强制换行 */
  overflow: hidden;
  /* 隐藏溢出部分 */
  text-overflow: ellipsis;
  /* 显示省略符号来代表被隐藏的文本 */
  display: -webkit-box;
  /* 将对象作为弹性伸缩盒子模型显示 */
  -webkit-box-orient: vertical;
  /* 设置盒子内排列顺序为纵向 */
  -webkit-line-clamp: 2;
  /* 限制块元素显示的文本的行数 */
}
.align-c {
  text-align: center;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.shrink-0 {
  flex-shrink: 0;
}
.shrink-1 {
  flex-shrink: 1;
}
.grow-0 {
  flex-grow: 0;
}
.grow-1 {
  flex-grow: 1;
}
.align-self-end {
  align-self: flex-end;
}
.bz-bd {
  box-sizing: border-box;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.van-empty /deep/ .van-empty__bottom {
  font-size: 14px;
  color: #a7a7a7;
}
.van-divider {
  margin: 0;
}
.hairline {
  transform: scale(0.5);
}
.wrap {
  position: fixed;
  z-index: 9999;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
}
.wrap .van-image {
  width: 100%;
  height: 100%;
}
.wrap .count-down {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  padding: 3px 7px;
  text-align: center;
  line-height: 150%;
  font-size: 14px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
}
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 覆盖vant ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
/* --- 自定义 以_开头 ---------------------------- */
