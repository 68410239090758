







































































































































































































// @font-face {
//   font-family: 'sy-m';
//   src: url('./assets/sy.otf');
// }

// @font-face {
//   font-family: 'sy-r';
//   font-weight: 400;
//   src: url('./assets/sy.otf');
// }

// @font-face {
//   font-family: 'sy-b';
//   font-weight: 700;
//   src: url('./assets/sy.otf');
// }

// @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-top)) {
//   body {
//     padding-bottom: constant(safe-area-inset-bottom);
//     padding-bottom: env(safe-area-inset-top);
//   }
// }

#app {
  position: relative;
  // overflow: scroll;
  height: 100vh;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma, Simsun, sans-serif;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: white;
  -webkit-overflow-scrolling: touch;
}

// .ignore-body {
//   max-width: 750px;
// }

.rui-tabbar {
  background-color: white;
}

[v-cloak] {
  display: none;
}

@hack: true;
          @import "E:\project\lfx_simple_frontend\src\assets\css\mixin.less";
          @import "E:\project\lfx_simple_frontend\src\assets\css\variable.less";
          ;